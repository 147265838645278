<script setup lang="ts">
import { Badge } from '@laam/ui/base';
import { inject } from 'vue';

interface DiscountBadgeProps {
	discountPercentage: string;
}

const showNov11Sale = inject('showNov11Sale') as boolean;

defineProps<DiscountBadgeProps>();
</script>
<template>
	<Badge
		size="xs"
		class="px-sm py-xxs lg:px-md rounded-small text-sm text-white bg-red-600 border-none"
		:class="{
			'bg-red-800': showNov11Sale,
		}"
	>
		-{{ discountPercentage }}%
	</Badge>
</template>
