<script setup lang="ts">
const props = defineProps<{
	class?: string;
	size?: string;
}>();
</script>
<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size || '16'"
		:height="size || '16'"
		viewBox="0 0 16 16"
		fill="currentColor"
		:class="props.class"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM8.35355 5.14645C8.15829 4.95118 7.84171 4.95118 7.64645 5.14645L5.95308 6.83981L4.3 5.6C4.14849 5.48637 3.94579 5.46809 3.77639 5.55279C3.607 5.63748 3.5 5.81061 3.5 6V9C3.5 9.53043 3.71071 10.0391 4.08579 10.4142C4.46086 10.7893 4.96957 11 5.5 11H10.5C11.0304 11 11.5391 10.7893 11.9142 10.4142C12.2893 10.0391 12.5 9.53043 12.5 9V6C12.5 5.81061 12.393 5.63748 12.2236 5.55279C12.0542 5.46809 11.8515 5.48637 11.7 5.6L10.0469 6.83981L8.35355 5.14645Z"
		/>
	</svg>
</template>
