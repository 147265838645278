<script setup lang="ts"></script>
<template>
	<div class="">
		<div
			class="flex py-md pl-3xl pr-sm justify-between items-center border-b border-gray-200 bg-white"
		>
			<div class="flex flex-col justify-center gap-xs flex-1">
				<div class="w-[103px] h-5xl rounded-full bg-gray-200"></div>
				<div class="w-[164px] h-[14px] rounded-full bg-gray-100"></div>
			</div>
		</div>
		<div class="flex pt-3xl pl-3xl items-center gap-xl bg-white">
			<div
				class="flex w-[550px] h-[354px] flex-col justify-center items-center rounded-xs bg-gray-100"
			>
				<div class="w-[80px] h-[80px] rounded-full"></div>
			</div>
			<div
				class="flex w-[251px] h-[354px] flex-col justify-center items-center rounded-xs bg-gray-100"
			>
				<div class="w-[80px] h-[80px] rounded-full"></div>
			</div>
		</div>
		<div class="flex p-3xl flex-col gap-xl bg-white">
			<div class="flex flex-col gap-xs">
				<div class="w-[126px] h-7xl rounded-full bg-gray-200"></div>
				<div class="flex gap-xs">
					<div class="w-[96px] h-[16px] rounded-full bg-gray-100"></div>
					<div class="w-[96px] h-[16px] rounded-full bg-gray-100"></div>
					<div class="w-[96px] h-[16px] rounded-full bg-gray-100"></div>
					<div class="w-[96px] h-[16px] rounded-full bg-gray-100"></div>
				</div>
			</div>
			<div class="w-full h-[1px] bg-gray-200"></div>
			<div class="flex flex-col gap-md">
				<div class="flex flex-col gap-xs">
					<div class="flex gap-xs">
						<div class="w-[89px] h-3xl rounded-full bg-gray-200"></div>
						<div class="w-3xl h-3xl rounded-full bg-gray-200"></div>
					</div>
					<div class="w-[198px] h-3xl rounded-full bg-gray-100"></div>
					<div class="w-[72px] h-3xl rounded-full bg-gray-100"></div>
				</div>
				<div class="flex gap-md">
					<div class="w-[72px] h-3xl rounded-xs bg-gray-100"></div>
					<div class="w-[72px] h-3xl rounded-xs bg-gray-100"></div>
				</div>
			</div>
		</div>
	</div>
</template>
