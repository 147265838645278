<script setup lang="ts">
import { ShippingTruck } from '@laam/ui/icons';

interface Props {
	shippingDays: string;
}

const props = defineProps<Props>();
</script>

<template>
	<div
		class="rounded border inline-flex flex-nowrap lg:h-7xl items-center font-semibold py-xxs lg:py-xs px-xs lg:px-sm gap-xxs bg-blue-dark-50 border-blue-dark-200 [&_svg]:fill-blue-dark-600"
	>
		<ShippingTruck weight="fill" />
		<p
			class="text-xs tracking-[-0.24px] font-semibold text-blue-dark-600 !text-nowrap"
		>
			{{ `${props.shippingDays} Days` }}
		</p>
	</div>
</template>
