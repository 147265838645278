<template>
	<svg
		height="18"
		viewBox="0 0 42 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width="42" height="24" rx="6" fill="#912018" />
		<path d="M8.12876 6H6V18H8.12876V6Z" fill="white" />
		<path d="M16.436 6H14.3073V18H16.436V6Z" fill="white" />
		<path d="M27.7406 6H25.6118V18H27.7406V6Z" fill="white" />
		<path d="M36 6H33.8712V18H36V6Z" fill="white" />
		<path d="M22.0883 16.9501H19.9595V18H22.0883V16.9501Z" fill="white" />
	</svg>
</template>
