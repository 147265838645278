<script setup lang="ts">
import {
	Dialog,
	DialogContent,
	DrawerHeader,
	IconButton,
	Drawer,
	DrawerContent,
	DrawerClose,
	DialogTrigger,
	DrawerTrigger,
	Skeleton,
} from '@laam/ui/base';
import { useFetchProductByHandle } from '~/data/product.ts';
import ProductDetail from '~/components/product-detail/index.vue';
import { PhX } from '@phosphor-icons/vue';
import { HandbagQuickAdd } from '@laam/ui/icons';
import ProductQuickAddSkeleton from './ProductQuickAddSkeleton.vue';

const { $cathodeClient } = useNuxtApp();

interface ProductQuickAdd {
	handle: string;
	productCardComponent?: object;
	dataSource?: {
		dataSource: string;
		clickedFrom: string[];
		version: string | undefined;
	};
}
const props = defineProps<ProductQuickAdd>();
const open = ref(false);
const LoganStore = useLoganStore();
const { accessToken } = storeToRefs(LoganStore);

const response = await useFetchProductByHandle(
	props.handle as string,
	open,
	accessToken,
);
const close = () => {
	open.value = false;
};
provide('close', close);
const pdpQuickAddExperiment = inject('pdpQuickAddExperiment') as Ref<boolean>;
provide('quickAddForm', true);
const fireEvent = () => {
	$cathodeClient.queueEvent('product_card_click', {
		source: window.location.href,
		redirectUrl: `/products/${props.handle}`,
		clicked_from: props?.dataSource?.clickedFrom.join(','),
		data_source: `${props?.dataSource?.dataSource} - ${props?.dataSource?.version}`,
		horizontalIndex: undefined,
	});
};
</script>

<template>
	<Drawer v-if="isMobileView()" v-model:open="open" @update:open="open != open">
		<DrawerTrigger :as-child="true">
			<IconButton
				v-if="!pdpQuickAddExperiment"
				class="right-md bottom-md absolute"
				size="sm"
				variant="secondary"
				rounded="full"
				@click.prevent="
					$event.stopPropagation();
					open = true;
				"
			>
				<HandbagQuickAdd size="20" />
			</IconButton>
			<component
				:is="productCardComponent"
				v-else
				@click.prevent="
					$event.stopPropagation();
					open = true;
					fireEvent();
				"
			></component>
		</DrawerTrigger>
		<DrawerContent slot-class="px-none pb-[100px] overflow-hidden pt-none">
			<DrawerHeader
				class="gap-md px-3xl pb-md flex items-center justify-between w-full pt-0 border-b border-gray-200"
			>
				<div class="gap-xxs flex flex-col items-start">
					<h2
						v-if="response.data.value"
						class="font-semibold text-gray-900 truncate"
					>
						{{ response.data.value.brand }}
					</h2>
					<Skeleton v-else class="h-3xl w-[40%] rounded-full" />

					<h3 v-if="response.data.value" class="text-xs text-gray-500 truncate">
						{{ response.data.value.title }}
					</h3>
					<Skeleton v-else class="h-3xl w-[40%] rounded-full" />
				</div>
				<DrawerClose>
					<IconButton variant="tertiary" size="lg">
						<PhX />
					</IconButton>
				</DrawerClose>
			</DrawerHeader>
			<div
				class="product--quickview mt-3xl w-full h-full pb-[104px] max-h-[80vh] overflow-auto"
			>
				<ProductQuickAddSkeleton
					v-if="response.isLoading.value || response.isError.value"
					class="h-full"
				/>

				<ProductDetail
					v-else
					:product-data="response.data.value"
					parent="quick-view"
				/>
			</div>
		</DrawerContent>
	</Drawer>
	<Dialog v-else>
		<DialogTrigger as-child>
			<IconButton
				class="right-md bottom-md absolute"
				size="sm"
				variant="secondary"
				rounded="full"
				@click.prevent="
					$event.stopPropagation();
					open = true;
				"
			>
				<HandbagQuickAdd size="20" />
			</IconButton>
		</DialogTrigger>
		<DialogContent
			class="max-w-[80vw] w-full rounded-xl max-h-[768px] overflow-auto"
		>
			<ProductQuickAddSkeleton
				v-if="response.isLoading.value || response.isError.value"
			/>
			<ProductDetail
				v-else
				:product-data="response.data.value"
				parent="quick-view"
				class="w-full"
			/>
		</DialogContent>
	</Dialog>
</template>
