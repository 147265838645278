<script setup lang="ts">
import { Crown } from '@laam/ui/icons';
import { computed, inject, ref } from 'vue';

interface ProductPardPriceProps {
	hvcPrice: string | null;
	originalPrice: string;
	discountedPrice: string | null;
	compareAtPrice: string;
	laamStore?: boolean;
}

const props = defineProps<ProductPardPriceProps>();

const currencySymbol = inject('currencySymbol');
const getFormattedPrice = inject('getFormattedPrice');

const displayPriceColorMap = {
	hvc: /*tw*/ 'text-orange-600',
	discount: /*tw*/ 'text-error-600',
	normal: /*tw*/ 'text-gray-800',
};

const strikeThroughPriceColorMap = {
	hvc: /*tw*/ 'text-error-600',
	normal: /*tw*/ 'text-gray-600',
};

type DisplayColorTypes = keyof typeof displayPriceColorMap;
type StrikeThroughColorTypes = keyof typeof strikeThroughPriceColorMap;

const displayPriceColor = ref<DisplayColorTypes>('normal');
const strikeThroughPriceColor = ref<StrikeThroughColorTypes>('normal');

const isHvcPriceAvailable = computed(() => props.hvcPrice !== null);
const isDiscountAvailable = computed(() => !!props.discountedPrice);

const hvcExtraDiscount = computed(() => {
	if (isHvcPriceAvailable.value && props.discountedPrice) {
		return Math.floor(
			((parseFloat(props.hvcPrice!) - parseFloat(props.discountedPrice)) /
				parseFloat(props.discountedPrice)) *
				100,
		);
	}
	return null;
});

const displayPrice = computed(() => {
	if (isHvcPriceAvailable.value) {
		// eslint-disable-next-line vue/no-side-effects-in-computed-properties
		displayPriceColor.value = 'hvc';
		return parseFloat(props.hvcPrice!);
	} else if (isDiscountAvailable.value) {
		// eslint-disable-next-line vue/no-side-effects-in-computed-properties
		displayPriceColor.value = 'discount';
		return parseFloat(props.discountedPrice!);
	} else {
		// eslint-disable-next-line vue/no-side-effects-in-computed-properties
		displayPriceColor.value = 'normal';
		return parseFloat(props.originalPrice);
	}
});

const strikeThroughPrice = computed(() => {
	if (isDiscountAvailable.value && isHvcPriceAvailable.value) {
		// eslint-disable-next-line vue/no-side-effects-in-computed-properties
		strikeThroughPriceColor.value = 'hvc';
		return parseFloat(props.discountedPrice!);
	} else if (isDiscountAvailable.value || isHvcPriceAvailable.value) {
		// eslint-disable-next-line vue/no-side-effects-in-computed-properties
		strikeThroughPriceColor.value = 'normal';
		return parseFloat(props.originalPrice);
	} else {
		return null;
	}
});

const noRoundPrice = computed(() => {
	return (price: number) => {
		if (
			props.laamStore &&
			(props.compareAtPrice === '0' ||
				price === parseFloat(props.compareAtPrice))
		) {
			return false;
		}
		return true;
	};
});
</script>
<template>
	<div class="product_card_price gap-xxs lg:gap-xs flex flex-col">
		<div class="gap-xs flex items-center">
			<!-- Escobar Icon if applicable -->
			<Crown v-if="isHvcPriceAvailable" class="fill-orange-600" />
			<!-- Price -->
			<p
				:class="`display-price text-sm lg:text-md  ${displayPriceColorMap[displayPriceColor]}`"
			>
				<!-- Currency Symbol -->
				<!-- TODO: Dynamically get from config -->
				<span>{{ currencySymbol }}</span>
				{{ ` ` }}
				<span class="font-semibold">{{
					getFormattedPrice(
						displayPrice,
						noRoundPrice(displayPrice) || isHvcPriceAvailable,
					)
				}}</span>
			</p>
		</div>
		<p v-if="!!strikeThroughPrice" :class="`text-xs lg:text-sm`">
			<span
				:class="`original-price line-through ${strikeThroughPriceColorMap[strikeThroughPriceColor]}`"
			>
				{{ currencySymbol }}
				<span class="lg:font-normal font-semibold">
					{{
						getFormattedPrice(
							strikeThroughPrice,
							noRoundPrice(strikeThroughPrice),
						)
					}}
				</span>
			</span>

			<span v-if="!!hvcExtraDiscount" class="text-gray-600 no-underline">
				{{ ` , Extra ${hvcExtraDiscount}%` }}
			</span>
		</p>
	</div>
</template>
