<script setup lang="ts">
const props = withDefaults(
	defineProps<{
		class: string;
	}>(),
	{
		class: '',
	},
);
</script>

<template>
	<svg
		width="12"
		height="12"
		viewBox="0 0 12 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		:class="props.class"
	>
		<path
			d="M11.5983 5.48438L10.942 3.84375C10.8863 3.70487 10.7902 3.58592 10.6661 3.50234C10.5419 3.41875 10.3956 3.37439 10.2459 3.375H8.625V3C8.625 2.90054 8.58549 2.80516 8.51517 2.73484C8.44484 2.66451 8.34946 2.625 8.25 2.625H1.125C0.926088 2.625 0.735322 2.70402 0.59467 2.84467C0.454018 2.98532 0.375 3.17609 0.375 3.375V8.625C0.375 8.82391 0.454018 9.01468 0.59467 9.15533C0.735322 9.29598 0.926088 9.375 1.125 9.375H1.92188C2.00449 9.69766 2.19214 9.98364 2.45524 10.1879C2.71834 10.3921 3.04194 10.5029 3.375 10.5029C3.70806 10.5029 4.03166 10.3921 4.29476 10.1879C4.55786 9.98364 4.74551 9.69766 4.82812 9.375H7.17188C7.25449 9.69766 7.44214 9.98364 7.70524 10.1879C7.96834 10.3921 8.29194 10.5029 8.625 10.5029C8.95806 10.5029 9.28166 10.3921 9.54476 10.1879C9.80786 9.98364 9.99551 9.69766 10.0781 9.375H10.875C11.0739 9.375 11.2647 9.29598 11.4053 9.15533C11.546 9.01468 11.625 8.82391 11.625 8.625V5.625C11.6251 5.57686 11.616 5.52914 11.5983 5.48438ZM3.375 9.75C3.22666 9.75 3.08166 9.70601 2.95832 9.6236C2.83499 9.54119 2.73886 9.42406 2.68209 9.28701C2.62532 9.14997 2.61047 8.99917 2.63941 8.85368C2.66835 8.7082 2.73978 8.57456 2.84467 8.46967C2.94956 8.36478 3.0832 8.29335 3.22868 8.26441C3.37417 8.23547 3.52497 8.25032 3.66201 8.30709C3.79906 8.36386 3.91619 8.45999 3.9986 8.58332C4.08101 8.70666 4.125 8.85166 4.125 9C4.125 9.19891 4.04598 9.38968 3.90533 9.53033C3.76468 9.67098 3.57391 9.75 3.375 9.75ZM1.125 6.375V3.375H7.875V6.375H1.125ZM8.625 9.75C8.47666 9.75 8.33166 9.70601 8.20832 9.6236C8.08499 9.54119 7.98886 9.42406 7.93209 9.28701C7.87532 9.14997 7.86047 8.99917 7.88941 8.85368C7.91835 8.7082 7.98978 8.57456 8.09467 8.46967C8.19956 8.36478 8.3332 8.29335 8.47868 8.26441C8.62417 8.23547 8.77497 8.25032 8.91201 8.30709C9.04906 8.36386 9.16619 8.45999 9.2486 8.58332C9.33101 8.70666 9.375 8.85166 9.375 9C9.375 9.19891 9.29598 9.38968 9.15533 9.53033C9.01468 9.67098 8.82391 9.75 8.625 9.75ZM8.625 5.25V4.125H10.2459L10.6959 5.25H8.625Z"
			fill="#1570EF"
		/>
	</svg>
</template>
